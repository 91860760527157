<template>
	<div class="wizard-products">
		<h3 class="section-title text-center">SELECT KEGS</h3>
		<p class="text-center">Not sure how many you need? Try our <a :href="`${contentUrl}/calculator`">Keg Calculator</a>.<br>We encourage full pallet orders to maximize the efficiencies and costs of your shipping options.</p>
		<div class="wizard-products__list" v-if="cart">
			<div class="wizard-products__item" v-for="(product, key) in productsWithCart" :key="key" :id="`product_${product.$key}`">
				<div class="wizard-products__item-image">
					<img :src="product.image" :alt="product.name">
					<img src="~@/assets/images/keg-tap.png" width="76" height="76" class="wizard-products__item-image-tap">
				</div>
				<h3 class="wizard-products__item-name">
					{{product.name}}
				</h3>
				<p class="wizard-products__item-description" v-html="product.description"></p>
				<div class="wizard-products__item-pricing">
					<span class="wizard-products__item-price">{{product.discountPrice || product.price | currency}}</span>/unit
				</div>
				<div class="wizard-products__item-per">
					{{product.perPallet}} kegs per pallet
				</div>

				<div class="wizard-products__item-quantity form-group">
					<label v-if="product.status !== 'unavailable'">
						<div>QTY.</div>
						<div class="wizard-products__item-selector">
							<a @click="addRemoveQuantity(product, -1)" class="fab"><i class="icon">remove</i></a>
							<input type="number" class="form-control" size="5" min="0" steps="1" placeholder="0" v-model="product.quantity" @change="adjustQuantity(product)">
							<a @click="addRemoveQuantity(product, 1)" class="fab"><i class="icon">add</i></a>
						</div>
					</label>
					<h3 v-if="product.status === 'unavailable'">Temporarily Out of Stock</h3>
					<small v-if="product.status === 'unavailable'"><a :href="`${contentUrl}/contact-us/`">Contact Us</a> for an update on availability</small>
				</div>
			</div>
		</div>

		<div class="text-center col-md-12 line">
			<container-cta></container-cta>

			<a href="tel:17274999930"><img src="~@/assets/images/banner-barrels.png" width="904" height="306" class="max-width" id="banner_customization_image"></a>
		</div>
	</div>
</template>

<script>
// Dependencies
import { track } from '@/lib/analytics'
import ContainerCta from '@/components/ContainerCta.vue'

// Component
export default {
	name: 'wizardProducts',
	components: {
		ContainerCta,
	},
	data: () => ({
		urlParsed: true,
		contentUrl: process.env.VUE_APP_CONTENT_SITE_URL,
	}),
	computed: {
		cart() {
			return this.$store.getters['cart/cart']
		},
		products() {
			return this.$store.getters['products/products']
		},
		productsWithCart() {
			if (!this.cart || !this.products) return {}
			const products = []
			for(const key in this.products) {
				const product = this.products[key]
				if (this.cart.products && this.cart.products[key]) {
					product.quantity = this.cart.products[key].quantity || 0
					product.discountPrice = this.cart.products[key].discountPrice
				} else {
					product.quantity = 0
					product.discountPrice = 0
				}
				// products[key] = product
				products.push(product)
			}

			return products.sort((a, b) => b.weight - a.weight).reverse()
		},
	},
	created() {
		if (this.$route.query && this.$route.query.products) {
			this.urlParsed = false
		}

		this.$store.dispatch('products/load').then(res => {
			for(const productId in res) {
				const product = this.products[productId]
				let i = 0
				track('ec:addImpression', {
					id: product.$key,
					name: product.name,
					category: 'Keg',
					list: 'Kegs Wizard',
					position: i+1,
				})
			}
		})
	},
	methods: {
		addRemoveQuantity(product, delta) {
			const trackParams = {
				'id': product.$key,
				name: product.name,
				category: 'Kegs',
				price: product.price,
				quantity: product.quantity,
			}
			if (delta > 0) {
				this.$store.dispatch('cart/addToCart', product)
				track('ec:addProduct', trackParams)
			} else {
				this.$store.dispatch('cart/removeFromCart', product)
				track('ec:removeProduct', trackParams)
			}
			track('ec:setAction', 'update')
			track('send', 'event', 'ux', 'click', 'update quantity')
		},
		adjustQuantity(product) {
			this.$store.dispatch('cart/updateQuantity', product)
		},
		urlQuantities() {
			const requestProducts = JSON.parse(this.$route.query.products)
			for(const productId in requestProducts) {
				if (this.products[productId]) {
					const quantity = requestProducts[productId]
					const product = this.products[productId]
					if (product && product.status !== 'unavailable') {
						product.quantity = quantity
						this.adjustQuantity(product)
					}
				}
			}
		},
	},
	watch: {
		productsWithCart() {
			if (!this.urlParsed) {
				this.urlParsed = true
				this.urlQuantities()
			}
		},
	},
}
</script>

<style scoped lang="scss">
.wizard-products {

	&__list {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			flex-direction: column;
		}
	}

	&__item {
		box-sizing: border-box;
		display: flex;
		flex: 1;
		flex-direction: column;
		overflow: hidden;
		padding: $margin;
		text-align: center;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			display: block;
			text-align: left;
			border-bottom: 1px solid color_('gray');
			margin-bottom: $line;
		}

		&-image {
			max-width: 100%;
			margin-bottom: $line;
			position: relative;

			@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
				float:right;
				max-width: 40%;
				padding:0 20px;
			}

			&-tap {
				position: absolute;
				right: 0;
				bottom: 0;

				@media screen and (max-width: map-get($grid-breakpoints,'md')) {
					width: 50px;
					height: auto;
				}

				@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
					display: none;
				}
			}
		}

		&-selector {
			display: flex;
			width: 100%;
			margin-top: $line-sm;

			.form-control {
				width: 4em;
				border-radius: 0;
				box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24);
				border:0;
				font-weight: 400;
				font-size: 18px;
				text-align: center;
				height: 43px;
				margin:5px 10px 0;
			}

			.fab {
				@media screen and (max-width: map-get($grid-breakpoints,'md')) {
					height: 33px;
					width: 33px;
					line-height: 22px;
					margin-top: 9px;
				}
			}
		}

		&-name {
			font-family: $font-family-secondary;
			text-transform: uppercase;
			margin-bottom: $line;
			font-size: 24px;
		}

		&-description {
			//flex: 1;
			font-size: 14px;
			margin-bottom:$line;
			max-height: 9.5em;
		}

		&-pricing {
			margin-bottom: $line;
			color:color_('gray','smoke');
			font-family: $font-family-secondary;
			font-weight: 300;

			span {
				text-transform: uppercase;
				font-size: 24px;
				color:color_('gray','medium');
				font-weight: 400;
			}
		}

		&-per {
			color: darken(color_('gray','smoke'),20);
			margin-top: -$line/2;
			// font-family: $font-family-secondary;
			font-size: 14px;
			padding-bottom: $line/2;
		}

		&-quantity {
			label {
				font-family: $font-family-secondary;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 300;
				color:color_('gray','smoke');
				margin: $line-sm 0;
			}

			@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
				margin-bottom: 0;
				text-align: center;
				display: flex;
			}
		}
	}

	&__meta {
		font-family: $font-family-secondary;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			margin: 20px 0;
		}
	}
}
</style>
