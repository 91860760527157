<template>
	<div class="wizard">
		<site-header class="wizard__top"></site-header>
		<div class="wizard__middle container">
			<div class="row">
				<div class="wizard__sidebar col-xl-3 col-md-4">
					<router-view name="sidebar"></router-view>
				</div>
				<div class="wizard__content col-xl-9 col-md-8">
					<router-view name="contentHeader" class="wizard__content-header"></router-view>
					<router-view name="error" class="main__content-body" v-if="errorMessage"></router-view>
					<router-view name="content" class="wizard__content-body"></router-view>
				</div>
			</div>
		</div>

		<site-footer class="wizard__bottom"></site-footer>

		<modal
			@close="hideModal()"
			v-if="modalVisible"
			:header="modalTitle"
		>
			<router-view name="modal"></router-view>
		</modal>
	</div>
</template>

<script>
// Dependencies
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import Modal from '@/components/Modal.vue'

// Component
export default {
	name: 'viewWizard',
	components: {
		SiteHeader,
		SiteFooter,
		Modal,
	},
	props: {
		errorMessage: {
			type: String,
		},
	},
	data: () => ({
		modalVisible: false,
		modalTitle: '',
	}),
	computed: {
		steps() {
			return this.$store.getters['cart/steps']
		},
		currentStep() {
			return this.$store.getters['cart/currentStep'] - 1
		},
		pageTitle() {
			return this.steps[this.currentStep].title || 'Keg Ordering Wizard'
		},
	},
	mounted() {
		this.modalVisible = this.$route.meta.modalVisible
		this.modalTitle = this.$route.meta.modalTitle
	},
	methods: {
		hideModal() {
			this.$router.back()
		},
	},
	watch: {
		'$route'(to) {
			this.modalVisible = to.meta.modalVisible
			this.modalTitle = to.meta.modalTitle
		},
	},
	metaInfo() {
		return {
			title: this.pageTitle,
		}
	},
}
</script>

<style scoped lang="scss">
.wizard {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;

	&__middle {
		display: flex;
		flex-direction: row;
		flex: 1 0 auto;
		width: 100%;

		.row {
			min-width: 100%;
		}
	}

	&__sidebar {
		@media screen and (max-width: map-get($grid-breakpoints,'md')) {
			order: 2;
		}
	}
}
</style>
