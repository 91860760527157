<template>
	<div class="wizard-header">
		<div class="wizard-header__steps">
			<router-link :to="step.link" class="wizard-header__step" v-for="(step, key) in steps" :key="key" :class="{'wizard-header__step--active':key==currentStep, 'wizard-header__step--complete':key < currentStep}">
				<span>Step {{key + 1}}</span><br>
				<span class="wizard-header__step-title" v-html="step.title"></span>
				<div class="wizard-header__message" v-if="step.err && !steps[key-1].status">{{step.err}}</div>
			</router-link>
		</div>
	</div>
</template>

<script>
// Dependencies
import { track } from '@/lib/analytics'

// Component
export default {
	name: 'wizardHeader',
	computed: {
		steps() {
			return this.$store.getters['cart/steps']
		},
		currentStep() {
			return this.$store.getters['cart/currentStep'] - 1
		},
	},
	mounted() {
		track('ec:setAction', {step: this.$store.getters['cart/currentStep']})
	},
}
</script>

<style scoped lang="scss">
.wizard-header {
	margin-bottom: 50px;
	width: 100%;

	// @media screen and (max-width: $screen-md-min) {
	// 	margin: 0 0px 30px;
	// 	padding:0;
	// }

	&__steps {
		display: flex;
		align-items: space-between;
		justify-content: space-between;
	}

	&__step {
		flex: 1;
		text-align: left;
		font-family: $font-family-secondary;
		text-transform: uppercase;
		font-weight: 200;
		border-bottom: 4px solid color_('gray');
		padding-bottom: 10px;
		color: color_('gray','smoke');
		text-decoration: none;
		position: relative;

		&:hover {
			text-decoration: none;
			color: color_('gray','smoke');

			.wizard-header__message {
				opacity: 0.85;
			}
		}

		span {
			color: color_('gray','smoke');
			font-size: 16px;
			font-weight: 200;

			@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
				font-size: 12px;
			}

			&.wizard-header__step-title {
				font-weight: 500;
			}
		}

		&--complete {
			border-color: color_('brown','light');
			
			span {
				color: color_('brown','light');
			}
		}

		&--active,
		&--active:hover {
			border-color: color_('brown','light');
			color: color_('gray','medium');
			font-weight: 400;

			span {
				color: color_('gray','dark');
			}
		}
	}

	&__message {
		background-color: color_('white');
		border: 1px solid color_('red');
		border-radius: 5px;
		color: color_('red');
		font-size: 14px;
		font-weight: normal;
		opacity: 0;
		padding: 10px;
		position: absolute;
		transform: translateX(-10%);
		transition: opacity 250ms $transition-cubic;
	}
}
</style>
