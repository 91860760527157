<template>
	<div class="wizard-summary">
		<h3 class="wizard-summary__title">
			Your Order
			<a class="wizard-summary__clear" @click="resetCart()" v-show="cart.subTotal>0">Clear <i class="icon">close</i></a>
		</h3>
		<div class="wizard-summary__products">
			<div class="wizard-summary__product" v-for="(product, key) in cart.products" :key="key">
				<span class="wizard-summary__product-quantity">{{product.quantity}}</span>
				<span class="wizard-summary__product-name">{{product.name}}</span>
				<span class="wizard-summary__product-price">{{(product.quantity * (product.discountPrice || product.price)) | currency}}</span>
			</div>
		</div>
		<div class="wizard-summary__shipping" v-if="cart.shipping && cart.shipping.method">
			<span class="wizard-summary__shipping-name">Shipping</span>
			<span class="wizard-summary__shipping-price">{{cart.shipping.method.price | currency}}</span>
		</div>
		<div class="wizard-summary__total">
			Purchase Total
			<span class="wizard-summary__price">{{cart.total | currency}}</span>
		</div>
		<hr class="divider">
		<div class="wizard-summary__next" v-if="nextStep">
			<button class="btn btn-primary" :disabled="!steps[currentStepIndex].status || cart._status === 'updating'" v-html="`Next: ${nextStep.label}`" @click="goNext()"></button>
		</div>
		<div class="wizard-summary__lease">
			<div class="wizard-summary__lease-intro">
				Lease for as low as
			</div>
			<div class="wizard-summary__lease-text">
				{{leaseLowest.amount / cart.totalQuantity | currency}}/mo<br>
				<small>per keg</small>
			</div>
			<div class="wizard-summary__lease-meta">
				POWERED BY
				<img src="~@/assets/images/bcl-logo-white.png" height="125" width="213" class="wizard-summary__lease-image">
			</div>
		</div>
	</div>
</template>

<script>
// Dependencies
import { track } from '@/lib/analytics'

// Component
export default {
	name: 'wizardSummary',
	computed: {
		cart() {
			return this.$store.getters['cart/cart']
		},
		leaseLowest() {
			return this.$store.getters['cart/leaseLowest']
		},
		steps() {
			const steps = this.$store.getters['cart/steps']
			if (!this.cart) return

			if (this.cart.subTotal > 0) {
				steps[0].status = true
			} else {
				steps[0].status = false
			}
			return steps
		},
		nextStep() {
			const steps = this.$store.getters['cart/steps']
			const currentStep = this.$store.getters['cart/currentStep']

			return steps[currentStep]
		},
		currentStepIndex() {
			const currentStep = this.$store.getters['cart/currentStep']
			return currentStep - 1
		},
	},
	methods: {
		resetCart() {
			this.$store.dispatch('cart/resetCart')
		},
		goNext() {
			track('ec:setAction', 'checkout', {'step': this.$store.getters['cart/currentStep']})
			track('pageview')
			this.$router.push({ ...this.nextStep.link })
		},
	},
}
</script>

<style scoped lang="scss">

.wizard-summary {
	background: color_('brown');
	box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.32);
	padding: 0;
	// margin-left: -$margin;
	// margin-right: -$margin;
	font-family: $font-family-secondary;
	color: color_('white');

	@media screen and (max-width: map-get($grid-breakpoints, 'md')) {
		margin-right: 0;
	}

	h3 {
		margin: 0;
		padding: 30px 20px;
		font-size: 20px;
		text-transform: uppercase;
	}

	&__product,
	&__shipping {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		background:rgba(255,255,255,.1);
		margin-bottom: 1px;
		font-weight: 300;
		padding: 14px 20px;
		font-size: 17px;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			font-size: 16px;
		}

		&-price {
			flex: 1;
			text-align: right;
			font-weight: 400;
		}

		&-name {
			margin-left: 5px;
		}

		&-quantity {
			margin-right: 5px;
		}
	}

	&__prices {
		//display: flex;
		padding: 10px 20px;

		strong {
			font-weight: 400;
		}
	}

	&__taxes {
		font-weight: 300;
		text-align: right;

		strong {
			margin-right: 20px;
		}

		.tool-tip {
			font-family: "Open Sans";
			font-size: 11px;
		}
	}

	&__total {
		padding: 14px 20px;
		font-size: 23px;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			font-size: 18px;
		}

		@media screen and (max-width: map-get($grid-breakpoints, 'md')) {
			font-size: 16px;
		}

		span {
			float: right;
		}
	}

	&__price {
		flex: 1;
		text-align: right;
	}

	.divider {
		height: 4px;
		background: color_('beige');
		border: 0;
		margin: 10px 20px;
	}

	&__lease {
		padding: $line $line-lg $line-lg;
		background: rgba(255, 255, 255, 0.1);

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			padding: $line $line $line-lg;
		}

		&-meta {
			font-size: 14px;
			font-weight: 200;

			@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
				text-align: center;
				margin-top: $line;
			}
		}

		&-image {
			max-width: 118px;
			height: auto;
			margin: $line-sm 0 0 $line-sm;
		}

		&-intro {
			//text-align: center;
			font-weight: 300;
			color: color_('white');
			text-transform: uppercase;
			letter-spacing: 2px;
			margin-bottom: $line;

			@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
				text-align: center;
			}
		}

		small {
			display: block;
			font-weight: 300;
			font-size: 22px;
		}

		&-text {
			display: block;
			text-align: left;
			font-size: 40px;
			line-height: 1em;

			@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
				font-size:28px;
			}

			@media screen and (max-width: map-get($grid-breakpoints, 'md')) {
				text-align: center;
			}
		}
	}

	&__next {
		padding: $line-sm $line $line;

		.btn {
			width: 100%;
			padding: 20px 0;
			text-align: center;

			@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
				font-size:16px;
			}
		}
	}

	&__clear {
		color: color_('white');
		font-weight: 300;
		float: right;
		font-size: 14px;

		.icon {
			font-size: 14px;
			line-height: 22px;
		}

		&:hover {
			color: color_('brown','light')
		}
	}

	&__note {
		padding: $line;
		font-family: "Open Sans";
		font-size: 12px;
		font-weight: normal;
		line-height: 1.5;
	}
}
</style>
